<template>
    <div class="col-container" ref="colContainer">
        <slot></slot>
    </div>
</template>

<script setup>
    import useContainer from './Containers.Container.ts';

    const props = defineProps({
        desktop: {
            type: Boolean,
            default: false
        },
        appContainer: {
            type: Boolean,
            default: false
        },
        noToolbar: {
            type: Boolean,
            default: false
        },
    });

    const [colContainer] = useContainer(props);
</script>